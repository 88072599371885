

import Vue from "vue"
import { getUserId } from "@/plugins/token"

export default Vue.extend({
    name: "UserMixin",
    methods: {
        getUserId
    }
})
