
import Vue from "vue"
import ListParagon, { Filter } from "@/components/ListParagon.vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import { state } from "@/plugins/state"
import ClientsFormatter from "@/components/workers/ClientsFormatter.vue"

interface OrderPeriod {
  id: number,
  started_at: string,
  finished_at: string
}

interface AddressPosition {
  [key: string]: number
}

interface OrderInterface {
  started_at: string,
  finished_at: string,
  id: number,
  position_id?: number,
  force_available_workers: boolean,
  force_all_periods: boolean,
  periods: Array<OrderPeriod>,
  address_position: AddressPosition
}

interface PositionInterface {
  id: number,
  name: string,
  priority: number | null
  totalDelegated: number
}

interface ClientInterface {
  id: number,
  name: string,
  positions: PositionInterface[]
}

interface Header {
  text: string,
  sortable: boolean,
  value: string,
  sortText?: string
}

export default Vue.extend({
  name: 'WorkerMatching',
  props: {
    id: {
      type: Number,
      required: true
    },
    selection: {
      type: Object,
      default: null
    },
    emitSelection: {
      type: Boolean,
    },
    isClosed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ListParagon,
    ClientsFormatter
  },
  data () {
    return {
      // show modal
      dialog: false,
      selectedItems: {},
      positionsFilter: 'associated' as string,
      order: {} as OrderInterface,
      selectionData: {},
      loading: true,
      titleModal: "favorite_jobs",
      data: null,
      workableFilter: false,
      select: null,
      disabled: true,
      reload: 0,
      statusArray: [
        {label: this.$vuetify.lang.t("$vuetify.order_status.select"), value: 1},
        {label: this.$vuetify.lang.t("$vuetify.order_status.in_progress"), value: 2},
        {label: this.$vuetify.lang.t("$vuetify.order_status.waiting"), value: 3},
        {label: this.$vuetify.lang.t("$vuetify.order_status.accepted"), value: 4},
        {label: this.$vuetify.lang.t("$vuetify.order_status.refused"), value: 5},
        {label: this.$vuetify.lang.t("$vuetify.order_status.cancelled"), value: 6},
      ]
    }
  },
  mounted(){
    this.getOrder()
    this.selectionData = this.$props.selection
    this.selectedItems = this.selectionData
  },
  watch : {
    selection() {
      this.selectionData = this.$props.selection
    },
    selectionData() {
      if (this.emitSelection) {
        this.reload++
      }
    },
  },
  computed: {
    filters: function(): Array<Filter> {
       let result = [
        {label: 'names', type: 'text-field'},
        {label: 'email', type: 'text-field'},
        {label: 'mobile_phone_number', type: 'text-field'},
        {label: 'position_id', type: 'autocomplete', url:'/v1/clients/positions', text: 'name', value: 'position_id', search: 'name'},
        {label: 'favorite_jobs', type: 'text-field'},
        {label: 'postal_city_code', type: 'text-field'},
        {label: 'transportation_means', type: 'select',
          items:[
            {text: this.$vuetify.lang.t("$vuetify.car"), value: "car"},
            {text: this.$vuetify.lang.t("$vuetify.motorbike"), value: "motorbike"},
            {text: this.$vuetify.lang.t("$vuetify.bike_scooter"), value: "bike_scooter"},
            {text: this.$vuetify.lang.t("$vuetify.mass_transit"), value: "mass_transit"},
            {text: this.$vuetify.lang.t("$vuetify.moped"), value: "moped"},
            {text: this.$vuetify.lang.t("$vuetify.walk"), value: "walk"},
            {text: this.$vuetify.lang.t("$vuetify.carpool"), value: "carpool"}
          ],
          multiple: true
        }
      ] as Array<Filter>
      if (this.order.address_position !== undefined && this.order.address_position !== null) {
        result.push({
          label: 'address_distance',
          type: 'number',
          value: `${this.order.address_position.longitude};${this.order.address_position.latitude}`,
        } as Filter)
      } else {
        result.push({
          label: 'address_distance',
          type: 'number',
          value: ``,
          disable: true
        } as Filter)
      }
      return result
    },
    headers: function(): Array<Header> {
      let result = [
        {
          text: this.$vuetify.lang.t("$vuetify.card"),
          sortable: true,
          value: 'card'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.favorite_jobs"),
          sortable: true,
          value: 'favorite_jobs',
          sortText: this.$vuetify.lang.t("$vuetify.favorite_jobs_total")
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contact_details"),
          sortable: true,
          value: 'mobile_phone_number',
          sortText: this.$vuetify.lang.t("$vuetify.mobile_phone_number")
        },
        {
          text: this.$vuetify.lang.t("$vuetify.recruitment"),
          sortable: true,
          value:'follow_up',
          sortText: this.$vuetify.lang.t("$vuetify.recruitment_total")
        },
        {
          text: this.$vuetify.lang.t("$vuetify.address_distance"),
          sortable: true,
          value:'address_distance'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.order_priority"),
          sortable: true,
          value:'order_priority'
        }
      ]
      return result
    },
    url: function(): string|null {
      // don't fetch data if there is no order yet
      if (!this.order.id) {
        return null
      }
      let ret = `/v1/workers?fields=last_name,first_name,mobile_phone_number,email,profile_picture,transportation_means,favorite_jobs,postal_code,clients&deleted_at=false&order=false:${this.order.id}`
      // space filter
      if (state.agency !== null) {
        ret += `&agency_id=${state.agency}`
      }
      // positions filter
      if (this.positionsFilter == "unassociated") {
        ret += `&positions=false:${this.order.position_id}`
      } else if (this.positionsFilter == "associated") {
        ret += `&positions=true:${this.order.position_id}`
      }
      // workable filter
      if (this.workableFilter || this.order.force_available_workers) {
        this.order.periods.forEach((eachPeriod: OrderPeriod) => {
          ret += `&workable[]=true:${eachPeriod.started_at},${eachPeriod.finished_at}`
        })
      }
      // Distance filter
      if (this.order.address_position !== null && this.order.address_position !== undefined) {
        ret += `&address_distance=${this.order.address_position.longitude};${this.order.address_position.latitude}`
      }
      // BUGFIX - Trigger data get on ListParagon's url watcher when changing force_all_periods
      ret += `&refresh=${this.order.force_all_periods}`

      return ret
    }
  },
  methods: {
    // retrieve order's position priority
    // if worker is associated to the position
    getOrderPositionPriority(clients: ClientInterface[]): string {
      let priority = ""
      clients.forEach((client: ClientInterface) => {
        client.positions.forEach((position: PositionInterface) => {
          if (position.id == this.order.position_id && position.priority !== null) {
            priority = String(position.priority)
          }
        })
      })
      return priority
    },
    getOrder() {
      axios
      .get(`/v1/orders/${this.id}`)
      .then((response: AxiosResponse) => {
        this.order = response.data
        this.loading = false
      }).catch((error: AxiosError)=> {
        EventBus.$emit('snackbar',{axiosError: error})
        this.$router.push({ path: '/orders' })
      })
    },
    addOrders() {
      let body = []
      if (this.selectedItems) {
        for (const item of Object.keys(this.selectedItems)) {
          body.push({'worker_id': + item})
        }
        axios.post(`/v1/orders/${this.order.id}/workers`, body).then(()=> {
          // success snackbar
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.creation_success')})
          this.reloadData()
        }).catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
      }
    },
    reloadData() {
      // force refresh paragon
      this.$emit('addWorkers')
      this.reload++
      this.selectionData =this.selectedItems
    },
    checkLogo(name: string) {
      switch (name) {
        case 'bike_scooter':
          return 'bike'
        case 'mass_transit' :
          return 'bus'
        case 'carpool' :
          return 'car-2-plus'
        default:
          return name
      }
    },
    arraySelectedItems(selectParagon: Record<string, unknown>) {
      this.selectedItems = selectParagon
      if (this.emitSelection === false) {
        this.$emit('selectedWorkers', this.selectedItems)
      }
    },
    getClientPositions(data: Array<ClientInterface>) {
      let clients: string[] = []
      if (data) {
        data.forEach((client) => {
          client.positions.forEach((position: PositionInterface) => {
            clients.push(
              `${position.name} ${this.$vuetify.lang.t("$vuetify.at")} ${client.name}`
            )
          })
        })
      }
      return clients
    },
    openData(titleModal: string, data: any){
      this.dialog = true
      this.data = data
      this.titleModal = titleModal
    },
  }
})
